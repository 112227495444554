import { createSlice } from '@reduxjs/toolkit';
import { LoadingStatus, SortDirection } from '../../../../utils/constants';
import { getVisibleColumns } from '../../../../services/ObjectConfiguration/ObjectConfiguration.service';

const applySaveFilterHandler = (state, action) => {
  const fields = action.payload;

  if (!Array.isArray(fields) || fields.length === 0) {
    return;
  }

  const fieldObj = {};
  fields.forEach(({ Field, Value, Text }) => {
    fieldObj[Field] = Value;

    if (Text) {
      fieldObj[`${Field}_Text`] = Text;
    }
  });

  ['roleName', 'active'].forEach((fieldName) => {
    const fieldValue = fieldObj[fieldName];
    state.filter[fieldName] = fieldValue || '';
  });
};

const functionalityColumns = [
  'Checkbox',
  'FunctionalityName',
  // 'Module',
  'Search and View',
  'Add',
  'Edit',
  'Export',
  'Import',
  'Description',
];
const customFunctionalityColumns = ['Checkbox', 'FunctionalityName', 'Description'];

const initialFilterState = {
  roleName: '',
  active: true,
};

const initialPageState = {
  page: 0,
  pageSize: 100,
  sortColumn: 'RoleName',
  sortDirection: SortDirection.Asc,
};

const initialState = {
  filter: initialFilterState,
  tableInfo: initialPageState,

  isFilterVisible: true,
  isSearching: false,
  dataRowItems: [],
  totalRecordCount: 0,
  filterData: [],
  loadingFilterSettingStatus: LoadingStatus.Idle,

  loadingStatus: LoadingStatus.Idle,
  visibleGridColumns: [],
  visibleFunctionalityColumns: functionalityColumns,
  visibleCustomFunctionalityColumns: customFunctionalityColumns,
  mergedColumns: [],
  loadingGridColumnStatus: LoadingStatus.Idle,

  isSavedFilterLoaded: false,
  savedFilterList: [],

  isEditMode: false,
  isFunctionalityLoading: false,

  funtionalitiesByRoleId: {
    RoleName: '',
    Description: '',
    Active: true,
  },
};

const roleSearchSlice = createSlice({
  name: 'roleSearch',
  initialState,
  reducers: {
    showHideFilter(state, action) {
      state.isFilterVisible = action.payload;
    },
    applySavedFilter(state, action) {
      applySaveFilterHandler(state, action);
    },
    updateFilterValue(state, action) {
      const { propName, propValue } = action.payload;
      state.filter[propName] = propValue;
    },
    resetSearchFilter(state) {
      state.filter = { ...initialFilterState };
    },
    updatePageInfoField(state, action) {
      const { propName, propValue } = action.payload;
      state.tableInfo[propName] = propValue;
    },
    setPage(state, action) {
      state.tableInfo.page = action.payload;
    },
    updateLoadingStatus(state, action) {
      state.loadingStatus = action.payload;
    },
    searchRole(state, action) {
      state.dataRowItems = action.payload.roleList;
      state.totalRecordCount = action.payload.totalRoleCount;
    },
    updateVisibleGridColumns(state, action) {
      state.visibleGridColumns = action.payload;
    },
    updateMergedGridColumns(state, action) {
      state.mergedColumns = action.payload;
    },
    updateUserColumnSettings(state, action) {
      state.mergedColumns = action.payload;
      state.visibleGridColumns = getVisibleColumns(action.payload);
    },
    updateSavedFilterList(state, action) {
      state.isSavedFilterLoaded = true;
      state.savedFilterList = action.payload;
    },
    updateFilterShowHideSettings(state, action) {
      state.filterData = action.payload;
    },
    searchFilter(state, action) {
      state.filterData = action.payload;
    },
    updateLoadingColumnSettingStatus(state, action) {
      state.loadingGridColumnStatus = action.payload;
    },
    updateLoadingFilterSettingStatus(state, action) {
      state.loadingFilterSettingStatus = action.payload;
    },
    updateEditLoadingStatus(state, action) {
      state.isEditMode = action.payload.status;
    },
    updatefuncLoadingStatus(state, action) {
      state.isFunctionalityLoading = action.payload.status;
    },
    updateFuncByRoleID(state, action) {
      state.funtionalitiesByRoleId = action.payload.roleObj;
    },
    updateCheckbox(state, action) {
      const { propName, propFuncID, checked } = action.payload;
      const functionalityArr = state.funtionalitiesByRoleId.Functionalities;
      for (let i = 0; i < functionalityArr.length; i++) {
        if (functionalityArr[i].FunctionalityID === propFuncID) {
          if (functionalityArr[i].Options.includes(propName) && checked === false) {
            const arr = functionalityArr[i].Options.filter((item) => item !== propName);
            state.funtionalitiesByRoleId.Functionalities[i].Options = [...arr];
          }

          if (!functionalityArr[i].Options.includes(propName) && checked === true) {
            state.funtionalitiesByRoleId.Functionalities[i].Options.push(propName);
          }
        }
      }
    },
    updateAllCheckbox(state, action) {
      const { propName, checked } = action.payload;
      const functionalityArr = state.funtionalitiesByRoleId.Functionalities;
      for (let i = 0; i < functionalityArr.length; i++) {
        if (functionalityArr[i].Options.includes(propName) && checked === false) {
          const arr = functionalityArr[i].Options.filter((item) => item !== propName);
          state.funtionalitiesByRoleId.Functionalities[i].Options = [...arr];
        }

        if (!functionalityArr[i].Options.includes(propName) && checked === true) {
          state.funtionalitiesByRoleId.Functionalities[i].Options.push(propName);
        }
      }
    },
    updateRowCheckbox(state, action) {
      const { propFuncID, checked } = action.payload;
      const functionalityArr = state.funtionalitiesByRoleId.Functionalities;
      for (let i = 0; i < functionalityArr.length; i++) {
        if (functionalityArr[i].FunctionalityID === propFuncID && checked === false) {
          state.funtionalitiesByRoleId.Functionalities[i].Options = [];
        }

        if (functionalityArr[i].FunctionalityID === propFuncID && checked === true) {
          state.funtionalitiesByRoleId.Functionalities[i].Options = ['S', 'I', 'M', 'A', 'E', 'U'];
        }
      }
    },

    updateRowCheckboxCustomFunction(state, action) {
      const { customFunctionID, checked } = action.payload;
      const customFunctionArr = state.funtionalitiesByRoleId.CustomFunctions;
      const userCustomFunction = customFunctionArr.find(
        (x) => x.CustomFunctionID === customFunctionID
      );

      if (userCustomFunction) {
        userCustomFunction.IsSelected = checked;
      }
    },

    updatingSorting(state, action) {
      const { sortBy, sortOrder } = action.payload;
      state.tableInfo['sortColumn'] = sortBy;
      state.tableInfo['sortDirection'] = sortOrder;
    },
  },
});

export const filterFields = {
  roleName: 'roleName',
  active: 'active',
};

export const tableInfoFields = {
  page: 'page',
  pageSize: 'pageSize',
  sortColumn: 'sortColumn',
  sortDirection: 'sortDirection',
};

export const roleSearchActions = roleSearchSlice.actions;
export default roleSearchSlice.reducer;
