import { createSlice } from '@reduxjs/toolkit';
import { getVisibleColumns } from '../../../../services/ObjectConfiguration/ObjectConfiguration.service';
import { LoadingStatus, SortDirection } from '../../../../utils/constants';

const applySaveFilterHandler = (state, action) => {
  const fields = action.payload;

  if (!Array.isArray(fields) || fields.length === 0) {
    return;
  }

  const fieldObj = {};
  fields.forEach(({ Field, Value, Text }) => {
    fieldObj[Field] = Value;

    if (Text) {
      fieldObj[`${Field}_Text`] = Text;
    }
  });

  ['firstName', 'lastName', 'email', 'role', 'country', 'region', 'location'].forEach((fieldName) => {
    const fieldValue = fieldObj[fieldName];
    state.filter[fieldName] = fieldValue || '';
  });
};

const initialFilterState = {
  firstName: '',
  lastName: '',
  email: '',
  role: null,
  country: null,
  region: null,
  location: null,
};

const initialPageState = {
  page: 0,
  pageSize: 100,
  sortColumn: 'FirstName',
  sortDirection: SortDirection.Asc,
};

const initialState = {
  filter: initialFilterState,
  tableInfo: initialPageState,
  dataRowItems: [],
  totalRecordCount: 0,
  filterData: [],
  loadingFilterSettingStatus: LoadingStatus.Idle,

  loadingStatus: LoadingStatus.Idle, // idle, pending, success, failed

  mergedColumns: [],
  visibleGridColumns: [],
  loadingGridColumnStatus: LoadingStatus.Idle,

  savedFilterList: [],
  isFilterVisible: true,
  isSavedFilterLoaded: false,
};

const userSearchSlice = createSlice({
  name: 'userSearch',
  initialState,
  reducers: {
    showHideFilter(state, action) {
      state.isFilterVisible = action.payload;
    },
    updateFilterValue(state, action) {
      const { propName, propValue } = action.payload;
      state.filter[propName] = propValue;
    },
    resetSearchFilter(state) {
      state.filter = { ...initialFilterState };
    },
    resetSearchFiltersAndPagination(state, action) {
      state.filter = { ...initialFilterState, ...action.payload };
      state.tableInfo = { ...initialPageState };
    },
    updatePageInfoField(state, action) {
      const { propName, propValue } = action.payload;
      state.tableInfo[propName] = propValue;
    },
    setPage(state, action) {
      state.tableInfo.page = action.payload;
    },
    updateLoadingStatus(state, action) {
      state.loadingStatus = action.payload;
    },
    searchUser(state, action) {
      state.dataRowItems = action.payload.users;
      state.totalRecordCount = action.payload.totalUserCount;
    },
    searchFilter(state, action) {
      state.filterData = action.payload;
    },
    updateFilterShowHideSettings(state, action) {
      state.filterData = action.payload;
    },

    // - Start - Grid Columns Setting Actions
    updateUserColumnSettings(state, action) {
      state.mergedColumns = action.payload;
      state.visibleGridColumns = getVisibleColumns(action.payload);
    },
    updateLoadingColumnSettingStatus(state, action) {
      state.loadingGridColumnStatus = action.payload;
    },
    updateVisibleGridColumns(state, action) {
      state.visibleGridColumns = action.payload;
    },
    updateMergedGridColumns(state, action) {
      state.mergedColumns = action.payload;
    },
    // - End - Grid Columns Setting

    updateSavedFilterList(state, action) {
      state.isSavedFilterLoaded = true;
      state.savedFilterList = action.payload;
    },
    applySavedFilter(state, action) {
      applySaveFilterHandler(state, action);
    },
    updateLoadingFilterSettingStatus(state, action) {
      state.loadingFilterSettingStatus = action.payload;
    },
    updatingSorting(state, action) {
      const { sortBy, sortOrder } = action.payload;
      state.tableInfo['sortColumn'] = sortBy;
      state.tableInfo['sortDirection'] = sortOrder;
    },
  },
});

export const filterFields = {
  firstName: 'firstName',
  lastName: 'lastName',
  email: 'email',
  role: 'role',
  country: 'country',
  region: 'region',
  location: 'location',
};

export const tableInfoFields = {
  page: 'page',
  pageSize: 'pageSize',
  sortColumn: 'sortColumn',
  sortDirection: 'sortDirection',
};

export const userSearchActions = userSearchSlice.actions;
export default userSearchSlice.reducer;
