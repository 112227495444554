import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { AppBar, Box, Button, IconButton, Toolbar, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import MenuIcon from '../../../asset/icons/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import SearchIcon from '@mui/icons-material/Search';
import AccountPopover from '../../Popovers/AccountPopover/AccountPopover';
// import ContentSearch from "./ContentSearch";
import ThemeIcon from '../ThemeIcon';
// import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
// import NotificationsIcon from '@mui/icons-material/Notifications';
import LanguagePopover from '../../Popovers/LanguagePopover/LanguagePopover';
// import NotificationPopover from '../../Popovers/NotificationPopover/NotificationPopover';
// import FavouritePopover from '../FavouritePopover/FavouritePopover';
// import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
// import AnnouncementApi from '../../../services/api/AnnouncementsApi';
import SearchDialog from './SearchDialog';
import IF from '../../ui/IF';

export const openDrawerWidth = 270;
export const closeDrawerWidth = 80;
export const navBarHeight = 64;

const NavbarRoot = styled(AppBar)(({ theme, open }) => ({
  ...(theme.palette.mode === 'light' && {
    // backgroundColor: theme.palette.primary.main,
    backgroundColor: theme.palette.background.paper,
    // boxShadow: "none",
    // boxShadow: theme.shadows,
    boxShadow: 'rgba(37, 74, 135, 0.13) 0px 0px 28px 0px',

    color: theme.palette.primary.contrastText,
  }),
  ...(theme.palette.mode === 'dark' && {
    backgroundColor: theme.palette.background.paper,
    // borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: 'none',
  }),
  marginLeft: closeDrawerWidth,
  width: `calc(100% - ${closeDrawerWidth}px)`,
  ...(open && {
    marginLeft: openDrawerWidth,
    width: `calc(100% - ${openDrawerWidth}px)`,
    // transition: theme.transitions.create(['width', 'margin'], {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.enteringScreen,
    // }),
  }),
  zIndex: theme.zIndex.drawer,
}));

// const INITIAL_PAGE_NUM = 1;
// const PAGE_SIZE = 10;

const showSearchDialog = false;

const Navbar = (props) => {
  const { onSidebarMobileOpen, ...other } = props;
  const { open } = props;
  // const anchorEl = useRef(null);
  const theme = useTheme();
  const { t } = useTranslation();

  // const [openPopover, setOpenPopover] = useState(false);
  // const [isSearching, setIsSearching] = useState(false);
  // const [totalCount, setTotalCount] = useState(0);
  // const [showInfiniteLoader, setShowInfiniteLoader] = useState(true);
  // const [announcementList, setAnnouncementList] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);

  const menuBarIcon = (
    <IconButton color="primary" onClick={onSidebarMobileOpen}>
      {open && <MenuOpenIcon sx={{ fontSize: 34 }} />}
      {!open && <MenuIcon sx={{ fontSize: 32 }} />}
      {/* <MenuIcon fontSize="medium" /> */}
    </IconButton>
  );

  // const handleOpen = () => {
  //   setOpenPopover(true);
  // };

  const toggleSearchDialog = () => {
    setOpenDialog((prevVal) => !prevVal);
  };

  // const queryClient = new QueryClient({
  //   defaultOptions: {
  //     queries: {
  //       refetchOnWindowFocus: false,
  //       // keepPreviousData: true,
  //       refetchOnMount: false,
  //       refetchOnReconnect: false,
  //     },
  //   },
  // });

  // const fetchAnnouncementList = useCallback(async (pageNum) => {
  //   if (pageNum === INITIAL_PAGE_NUM) {
  //     setIsSearching(true);
  //   }

  //   try {
  //     const response = await AnnouncementApi.searchAnnouncementsForNotification({
  //       page: pageNum,
  //       pageSize: PAGE_SIZE,
  //       sortBy: 'CreatedOnDescending',
  //     });
  //     if (response?.Data) {
  //       setTotalCount(response?.Count);
  //       setAnnouncementList(response?.Data);
  //     }
  //     setIsSearching(false);
  //     setShowInfiniteLoader(false);
  //   } catch (err) {
  //     setShowInfiniteLoader(false);
  //     setIsSearching(false);
  //   }
  // }, []);

  // useEffect(() => {
  //   fetchAnnouncementList();
  // }, [fetchAnnouncementList]);

  return (
    <>
      <NavbarRoot {...other}>
        <Toolbar sx={{ minHeight: navBarHeight }}>
          {menuBarIcon}
          <Box
            sx={{
              flexGrow: 1,
              ml: 2,
            }}
          />

          <IF condition={showSearchDialog}>
            <Button
              sx={{
                display: 'flex',
                alignItems: 'center',
                padding: '4px 8px',
                backgroundColor: 'background.paper',
                border: `1px solid ${theme.palette.divider}`,
                borderRadius: '10px',
              }}
              startIcon={<SearchIcon />}
              onClick={toggleSearchDialog}
            >
              <Typography color="textPrimary" mx={1}>
                {`${t('Label_Search')}...`}
              </Typography>
              <Box
                sx={{
                  fontSize: '0.75rem',
                  fontWeight: 700,
                  lineHeight: '20px',
                  marginLeft: '8px',
                  border: `1px solid ${theme.palette.divider}`,
                  backgroundColor: 'background.default',
                  padding: '0px 4px',
                  borderRadius: '7px'
                }}
              >
                <Typography variant="button" color="textPrimary" mx={0.25} p={0}>
                  Ctrl+K
                </Typography>

              </Box>
            </Button>
          </IF>

          <LanguagePopover />
          {/* <Box sx={{ ml: 1 }}>
            <FavouritePopover />
          </Box> */}
          {/* <Box sx={{ ml: 1 }}>
          <ContentSearch />
        </Box> */}

          {/* <Box sx={{ ml: 1 }}>
            <IconButton
              onClick={handleOpen}
              ref={anchorEl}
            >
              {openPopover ? <NotificationsIcon fontSize="medium" color="primary" />
                : <NotificationsNoneIcon fontSize="medium" />}
            </IconButton>
            {openPopover && (
            <QueryClientProvider client={queryClient}>
              <NotificationPopover
                anchorRef={anchorEl}
                open={openPopover}
                setOpen={setOpenPopover}
                isSearching={isSearching}
                showInfiniteLoader={showInfiniteLoader}
                announcementList={announcementList || []}
                fetchAnnouncementList={fetchAnnouncementList}
                totalCount={totalCount}
                setShowInfiniteLoader={setShowInfiniteLoader}
              />
            </QueryClientProvider>
            )}
          </Box> */}

          <Box sx={{ ml: 0.5 }}>
            <ThemeIcon />
          </Box>

          <Box sx={{ ml: 2 }}>
            <AccountPopover />
          </Box>
        </Toolbar>
      </NavbarRoot>
      <IF condition={openDialog}>
        <SearchDialog
          toggleDrawer={toggleSearchDialog}
        />
      </IF>
    </>
  );
};

Navbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func,
};

export default Navbar;
