import PropTypes from 'prop-types';
import useAuth from '../../../hooks/useAuth';
import AuthorizationError from '../../../pages/error/AuthorizationError';

const PermissionGuard = ({ permission, right, children }) => {
  const { isAuthorized } = useAuth();

  const isAllowed = isAuthorized({ permissionCode: permission, right });
  if (!isAllowed) {
    return <AuthorizationError />;
  }

  return <>{children}</>;
};

PermissionGuard.propTypes = {
  children: PropTypes.node,
};

export const PermissionRights = {
  Search: 'S',
  Add: 'I',
  Edit: 'M',
  Approve: 'A',
  Export: 'E',
  Delete: 'D',
  Import: 'U',
};

export const PermissionCodes = {
  Action: 'Action',
  Content: 'Content',
  Email: 'Email',
  MyActions: 'MyActions',
  Cycle: 'Cycle',
  NAList: 'NAList',
  Enrollment: 'Enrollment',
  Program: 'Program',
  List: 'List',
  Dashboard: 'Dashboard',
  Organization: 'Organization',
  Audit: 'Audit',
  TestMethod: 'TestMethod',
  Location: 'Location',
  Country: 'Country',
  Unit: 'Unit',
  Region: 'Region',
  Contact: 'Contact',
  Role: 'Role',
  User: 'User',
  Announcement: 'Announcement',
  Group: 'Group',
  Calendar: 'Calendar'
};

export const CustomPermissions = {
  CanFinalizeSample: 'CanFinalizeSample',
};

export default PermissionGuard;
