import { lazy } from 'react';
import MainLayout from './components/layouts/MainLayout';
import Loadable from './components/ui/Loadable';
import { Navigate } from 'react-router-dom';
import AuthGuard from './components/Guards/AuthGuard/AuthGuard';
import PermissionGuard, { PermissionCodes, PermissionRights as Rights, } from './components/Guards/PermissionGuard/PermissionGuard';

// Authentication pages
const Login = Loadable(lazy(() => import('./pages/auth/Login')));
const ForgotPassword = Loadable(lazy(() => import('./pages/auth/components/ForgotPassword/ForgotPassword')));

// Contents page
const ContentsSearch = Loadable(
  lazy(() => import('./pages/ContentsSearch/ContentsSearch'))
);
const ContentsSearchAddForm = Loadable(
  lazy(() => import('./pages/ContentsSearch/components/ContentsSearchAddForm/ContentsSearchAddForm'))
);
const AuditOne = Loadable(
  lazy(() => import('./pages/ContentsSearch/components/AuditOne/AuditOne'))
);
const AuditTwo = Loadable(
  lazy(() => import('./pages/ContentsSearch/components/AuditTwo/AuditTwo'))
);

const StateGraph = Loadable(
  lazy(() => import('./pages/ContentsSearch/components/StateGraph/StateGraph'))
);
const ContentsSearchAddEditForm = Loadable(
  lazy(() => import('./pages/ContentsSearch/components/ContentSearchAddEditForm/ContentSearchAddEditForm'))
);
// List
const ListPage = Loadable(
  lazy(() => import('./pages/List/ListSearch'))
);
const EditList = Loadable(
  lazy(() => import('./pages/List/ListDetails'))
);

// cycle search
const ProgramSearch = Loadable(
  lazy(() => import('./pages/Program/ProgramSearch'))
);

// Error pages
const AuthorizationError = Loadable(
  lazy(() => import('./pages/error/AuthorizationError'))
);

const ProgramEdit = Loadable(
  lazy(() => import('./pages/Program/ProgramDetails'))
);

const MyActions = Loadable(
  lazy(() => import('./pages/MyActions/MyActions'))
);

const Calender = Loadable(
  lazy(() => import('./pages/Calender/Calender'))
);

const ActionSearch = Loadable(
  lazy(() => import('./pages/ActionSearch/ActionSearch'))
);

const LocationSearch = Loadable(
  lazy(() => import('./pages/Location/LocationSearch'))
);
const LocationSearchAddEditForm = Loadable(
  lazy(() => import('./pages/Location/components/LocationSearchAddEditForm/LocationSearchAddEditForm'))
);

const LocationView = Loadable(
  lazy(() => import('./pages/Location/components/LocationView/LocationView'))
);

const RoleSearch = Loadable(
  lazy(() => import('./pages/Role/RoleSearch'))
);
const RoleSearchAddEdit = Loadable(
  lazy(() => import('./pages/Role/components/RoleSearchAddEditForm/RoleSearchAddEditForm'))
);

const UserSearch = Loadable(lazy(() => import('./pages/User/UserSearch')));
const UserAddForm = Loadable(lazy(() => import('./pages/User/components/UserAddForm/UserAddForm')));

const UserEditForm = Loadable(lazy(() => import('./pages/User/components/UserEditForm/UserEditForm')));

const AccountSetting = Loadable(
  lazy(() => import('./pages/AccountSetting/AccountSetting'))
);

const Dashboard = Loadable(lazy(() => import('./pages/dashboard/Dashboard')));

const NotFound = Loadable(lazy(() => import('./pages/error/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/error/ServerError')));

const routes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <Dashboard />,
      },
    ]
  },
  {
    path: 'contents',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.Content} right={Rights.Search}>
            <ContentsSearch />
          </PermissionGuard>
        )
      },
      {
        path: 'add',
        element: (
          <PermissionGuard permission={PermissionCodes.Content} right={Rights.Add}>
            <ContentsSearchAddForm />
          </PermissionGuard>
        ),
      },
      {
        path: 'audit',
        element: (
          <PermissionGuard permission={PermissionCodes.Dashboard} right={Rights.Add}>
            <AuditOne />
          </PermissionGuard>)
      },
      {
        path: 'state',
        element: (
          <PermissionGuard permission={PermissionCodes.Dashboard} right={Rights.Add}>
            <StateGraph />
          </PermissionGuard>),
      },
      {
        path: ':ID/edit',
        element: (
          <PermissionGuard permission={PermissionCodes.Content} right={Rights.Edit}>
            <ContentsSearchAddEditForm />
          </PermissionGuard>
        ),
      },
      {
        path: 'audit1',
        element: (
          <PermissionGuard permission={PermissionCodes.Dashboard} right={Rights.Add}>
            <AuditTwo />
          </PermissionGuard>
        )
      },
    ]
  },
  {
    path: 'lists',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.List} right={Rights.Search}>
            <ListPage />
          </PermissionGuard>
        ),
      },
      {
        path: ':id/edit',
        element: (
          <PermissionGuard permission={PermissionCodes.List} right={Rights.Edit}>
            <EditList />
          </PermissionGuard>
        ),
      }
    ],
  },
  {
    path: 'myaction',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.MyActions} right={Rights.Search}>
            <MyActions />
          </PermissionGuard>
        ),
      }
    ],
  },
  {
    path: 'calendar',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.MyActions} right={Rights.Search}>
            <Calender />
          </PermissionGuard>
        ),
      }
    ],
  },
  {
    path: 'program',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.Program} right={Rights.Search}>
            <ProgramSearch />
          </PermissionGuard>
        ),
      },
      {
        path: 'add',
        element: (
          <PermissionGuard permission={PermissionCodes.Program} right={Rights.Add}>
            <ProgramEdit />
          </PermissionGuard>
        ),
      },
      {
        path: ':programId/edit',
        element: (
          <PermissionGuard permission={PermissionCodes.Program} right={Rights.Edit}>
            <ProgramEdit />
          </PermissionGuard>
        ),
      },
    ],
  },
  {
    path: 'action',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.Action} right={Rights.Search}>
            <ActionSearch />
          </PermissionGuard>
        ),
      },
    ],
  },
  {
    path: 'locations',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.Location} right={Rights.Search}>
            <LocationSearch />
          </PermissionGuard>
        ),
      },
      {
        path: 'add',
        element: (
          <PermissionGuard permission={PermissionCodes.Location} right={Rights.Add}>
            <LocationSearchAddEditForm />
          </PermissionGuard>
        ),
      },
      {
        path: ':id/edit',
        element: (
          <PermissionGuard permission={PermissionCodes.Location} right={Rights.Edit}>
            <LocationSearchAddEditForm />
          </PermissionGuard>
        ),
      },
      {
        path: ':id/view',
        element: (
          <PermissionGuard permission={PermissionCodes.Location} right={Rights.Search}>
            <LocationView />
          </PermissionGuard>
        ),
      },
    ],
  },
  {
    path: 'roles',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.Role} right={Rights.Search}>
            <RoleSearch />
          </PermissionGuard>
        ),
      },
      {
        path: 'add',
        element: (
          <PermissionGuard permission={PermissionCodes.Role} right={Rights.Add}>
            <RoleSearchAddEdit />
          </PermissionGuard>
        ),
      },
      {
        path: ':id/edit',
        element: (
          <PermissionGuard permission={PermissionCodes.Role} right={Rights.Edit}>
            <RoleSearchAddEdit />
          </PermissionGuard>
        ),
      },
    ],
  },
  {
    path: '/users',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.User} right={Rights.Search}>
            <UserSearch />
          </PermissionGuard>
        ),
      },
      {
        path: 'add',
        element: (
          <PermissionGuard permission={PermissionCodes.User} right={Rights.Add}>
            <UserAddForm />
          </PermissionGuard>
        ),
      },
      {
        path: ':id/edit',
        element: (
          <PermissionGuard permission={PermissionCodes.User} right={Rights.Edit}>
            <UserEditForm />
          </PermissionGuard>
        ),
      },
      // {
      //   path: ':userId/edit',
      //   element: (
      //     <PermissionGuard permission={PermissionCodes.User} right={Rights.Edit}>
      //       <UserAddEditForm />
      //     </PermissionGuard>
      //   ),
      // },
    ],
  },
  {
    path: 'account',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <AccountSetting />,
      },
    ],
  },
  {
    path: '*',
    children: [
      {
        path: '',
        exact: true,
        element: <Navigate to="/dashboard" />,
      },
      {
        path: 'login',
        element: <Login />,
      },
      {
        path: 'Auth/forgot',
        element: <ForgotPassword />,
      },
      {
        path: '401',
        element: <AuthorizationError />,
      },
      {
        path: '500',
        element: <ServerError />,
      },
      {
        path: '404',
        element: <NotFound />,
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
];

export default routes;
