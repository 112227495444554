import { Helmet } from 'react-helmet-async';
import { Box, Card, CardContent, Container, Link, Stack, useMediaQuery } from '@mui/material';

import styles from './Login.module.css';
import LegalBar from '../../components/layouts/LegalBar';

import { LoginForm } from './components';
// import Logo from '../../components/ui/Logo';

const whiteTextColor = '#000000';

const welcomeTextStyleProps = {
  background: 'linear-gradient(to right, rgba(145, 133, 190, 0.7) 30%, rgba(189, 17, 48, 0.7) 120%)',
  backgroundClip: 'text',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  fontWeight: '300' };

const Login = () => {
  const smUp = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const minHeightForm = smUp ? '567px' : '520px';

  return (
    <>
      <Helmet>
        <title>Knowledge Center | Login</title>
      </Helmet>

      <Stack
        sx={{
          background: 'url(\'/static/images/bg-login-3.svg\')',
          backgroundPosition: 'center',
          backgroundAttachment: 'fixed',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          minHeight: '100vh',
        }}
        alignItems="center"
        justifyContent="center"
      >
        <Container maxWidth="xs" sx={{ minHeight: minHeightForm }}>
          <Card
            sx={{
              backdropFilter: 'blur(10px)',
              background: 'rgba(255,255,255,0.4)'
            }}
            elevation={6}
          >
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                p: 4,
              }}
            >
              <Box
                sx={{
                  mt: -2,
                }}
              >
                <div
                  style={{
                    height: 150,
                    width: '100%',
                    opacity: 1,
                    background: 'url("/static/images/bvlogo_grey.svg") no-repeat center',
                  }}
                />

                <div className={styles.content} style={{ marginTop: 15 }}>
                  <div className={styles.content__container}>
                    <Stack className={styles.content__container__list} justifyContent="center" alignItems="center">
                      <div style={{ ...welcomeTextStyleProps }}>Welcome to</div>
                      <div style={{ ...welcomeTextStyleProps }}>Bienvenue sur</div>
                      <div style={{ ...welcomeTextStyleProps }}>Bienvenido a</div>
                      <div style={{ ...welcomeTextStyleProps }}>欢迎来到</div>
                      <div style={{ ...welcomeTextStyleProps }}>Bem-vindo ao</div>
                    </Stack>
                  </div>
                </div>

                <div style={{ color: 'rgba(189, 17, 48, 0.75)', marginTop: 4, textAlign: 'center', fontWeight: '700', fontSize: '26px' }}>
                  Knowledge Center
                </div>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  mb: 3
                }}
              >
                <LoginForm />
              </Box>

              <Link
                  // color="textSecondary"
                    // component={RouterLink}
                href="https://bureauveritas.onelogin.com/login2"
                variant="body2"
                target="_blank"
                rel="noreferrer"
                sx={{ ml: 'auto', fontStyle: 'italic', color: whiteTextColor, textDecoration: 'none', textDecorationColor: '#9b9fa7' }}
              >
                Forgot password?
              </Link>
            </CardContent>
          </Card>
        </Container>

      </Stack>

      {smUp && <LegalBar displayLogo displayCopyRight isLogin hideBottomBorder />}
    </>
  );
};

export default Login;
