import { createSlice } from '@reduxjs/toolkit';
import { getVisibleColumns } from '../../../../services/ObjectConfiguration/ObjectConfiguration.service';
import { LoadingStatus, SortDirection } from '../../../../utils/constants';

export const filterFields = {
  Title: 'Title',
  ActionStatus: 'ActionStatus',
  ActionType: 'ActionType',
  AssignedTo: 'AssignedTo',
  CreatedOnFrom: 'CreatedOnFrom',
  CreatedOnTo: 'CreatedOnTo',
  DueDateFrom: 'DueDateFrom',
  DueDateTo: 'DueDateTo'
};

const applySaveFilterHandler = (state, action) => {
  const fields = action.payload;
  if (!Array.isArray(fields) || fields.length === 0) {
    return;
  }

  const fieldObj = {};
  fields.forEach(({ Field, Value, Text }) => {
    fieldObj[Field] = Value;
    if (Text) {
      fieldObj[`${Field}_Text`] = Text;
    }
  });

  [
    filterFields.Title,
    filterFields.ActionType,
    filterFields.ActionStatus,
    filterFields.AssignedTo,
    filterFields.CreatedOnFrom,
    filterFields.CreatedOnTo,
    filterFields.DueDateFrom,
    filterFields.DueDateTo,
  ].forEach((fieldName) => {
    const fieldValue = fieldObj[fieldName];
    state.filter[fieldName] = fieldValue || '';
  });
};

const initialFilterState = {
  Title: '',
  ActionStatus: null,
  ActionType: null,
  AssignedTo: null,
  CreatedOnFrom: '',
  CreatedOnTo: '',
  DueDateFrom: '',
  DueDateTo: ''
};

const initialPageState = {
  page: 0,
  pageSize: 100,
  sortColumn: 'Title',
  sortDirection: SortDirection.Asc,
};

const initialState = {
  filter: initialFilterState,
  tableInfo: initialPageState,

  dataRowItems: [],
  totalRecordCount: 0,
  loadingStatus: LoadingStatus.Idle, // idle, pending, success, failed

  page: 1,
  sort: null,

  isFilterVisible: true,

  visibleGridColumns: [],
  mergedColumns: [],
  loadingGridColumnStatus: LoadingStatus.Idle,

  isSavedFilterLoaded: false,
  savedFilterList: [],
};

const actionsSearchSlice = createSlice({
  name: 'actionsSearch',
  initialState,
  reducers: {
    showHideFilter(state, action) {
      state.isFilterVisible = action.payload;
    },
    updateFilterValue(state, action) {
      const { propName, propValue } = action.payload;
      state.filter[propName] = propValue;
    },
    resetSearchFilter(state) {
      state.filter = { ...initialFilterState };
    },
    resetSearchFiltersAndPagination(state, action) {
      state.filter = { ...initialFilterState, ...action.payload };
      state.tableInfo = { ...initialPageState };
    },
    updatePageInfoField(state, action) {
      const { propName, propValue } = action.payload;
      state.tableInfo[propName] = propValue;
    },
    setPage(state, action) {
      state.tableInfo.page = action.payload;
    },
    updateLoadingStatus(state, action) {
      state.loadingStatus = action.payload;
    },
    searchActions(state, action) {
      state.dataRowItems = action.payload.actions;
      state.totalRecordCount = action.payload.totalActionCount;
    },
    // Start - Grid Columns Setting Actions
    updateUserColumnSettings(state, action) {
      state.mergedColumns = action.payload;
      state.visibleGridColumns = getVisibleColumns(action.payload);
    },

    updateLoadingColumnSettingStatus(state, action) {
      state.loadingGridColumnStatus = action.payload;
    },

    updateVisibleGridColumns(state, action) {
      state.visibleGridColumns = action.payload;
    },
    updateMergedGridColumns(state, action) {
      state.mergedColumns = action.payload;
    },
    // End - Grid Columns Setting

    updateSavedFilterList(state, action) {
      state.isSavedFilterLoaded = true;
      state.savedFilterList = action.payload;
    },

    applySavedFilter(state, action) {
      applySaveFilterHandler(state, action);
    },

    updatingSorting(state, action) {
      const { sortBy, sortOrder } = action.payload;
      state.tableInfo['sortColumn'] = sortBy;
      state.tableInfo['sortDirection'] = sortOrder;
    },
  },
});

export const tableInfoFields = {
  page: 'page',
  pageSize: 'pageSize',
  sortColumn: 'sortColumn',
  sortDirection: 'sortDirection',
};

export const actionsSearchActions = actionsSearchSlice.actions;
export default actionsSearchSlice.reducer;
