import HomeIcon from '@mui/icons-material/Home';
import useAuth from '../../../hooks/useAuth';
import { useCallback, useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PermissionCodes, PermissionRights } from '../../Guards/PermissionGuard/PermissionGuard';

import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import VerifiedUserRoundedIcon from '@mui/icons-material/VerifiedUserRounded';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import SettingsIcon from '@mui/icons-material/Settings';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ArticleIcon from '@mui/icons-material/Article';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';

const useNavSections = () => {
  const { t } = useTranslation();

  const sections = useMemo(
    () => [
      {
        title: t('Home'),
        path: '/',
        icon: <HomeIcon fontSize="small" />,
        items: [
          {
            title: 'Dashboard',
            icon: <DashboardIcon />,
            path: '/dashboard',
            permission: PermissionCodes.Dashboard,
          },
          {
            title: t('Nav_Menu_Contents'),
            icon: <ArticleIcon />,
            path: '/contents',
            permission: PermissionCodes.Content,
          },
          {
            title: t('Nav_Menu_MyActions'),
            icon: <NotificationsActiveIcon />,
            permission: PermissionCodes.MyActions,
            path: '/myaction',
          },
          {
            title: t('Nav_Menu_Actions'),
            icon: <NotificationImportantIcon />,
            permission: PermissionCodes.Action,
            path: '/action',
          },
          {
            title: t('Nav_Menu_Calender'),
            icon: <CalendarMonthIcon />,
            permission: PermissionCodes.Calendar,
            path: '/calendar',
          },
        ],
      },
      {
        title: t('Nav_Menu_Capability'),
        path: '/',
        icon: <HomeIcon fontSize="small" />,
        items: [
          {
            title: 'Locations',
            icon: <LocationOnRoundedIcon />,
            path: '/locations',
            permission: PermissionCodes.Location,
          },
        ],
      },
      {
        title: 'Master Data',
        icon: <SettingsIcon fontSize="small" />,
        items: [
          {
            title: 'List',
            icon: <FormatListBulletedIcon />,
            path: '/lists',
            permission: PermissionCodes.List,
          },
        ],
      },
      {
        title: 'Administrator',
        icon: <AdminPanelSettingsOutlinedIcon fontSize="small" />,
        items: [
          {
            title: 'Roles',
            icon: <VerifiedUserRoundedIcon />,
            path: '/roles',
            permission: PermissionCodes.Role,
          },
          { title: 'Users',
            icon: <PeopleRoundedIcon />,
            path: '/users',
            permission: PermissionCodes.User,
          },
        ],
      },
      /* {
      title: 'Cycle Search',
      icon: <CycleSearch />,
      path: '/cycleSearch',
    }, */
    ],
    [t]
  );
    // const useNavSections = () => {
    // console.log(sections);
  const { isAuthorized, assignedDivisions } = useAuth();

  const [authorizedSections, setAuthorizedSections] = useState([]);

  const updateNavStatus = useCallback(
    (navItem) => {
      if (!navItem) {
        return;
      }

      const { children } = navItem;
      if (!Array.isArray(children) || children.length === 0) {
        if (navItem.permission) {
          navItem.isAuthorized = isAuthorized({
            permissionCode: navItem.permission,
            right: PermissionRights.Search
          });

          if (navItem.isAuthorized && navItem.divisionId) {
            navItem.isAuthorized = !Array.isArray(assignedDivisions)
              || assignedDivisions.length === 0
              || assignedDivisions.includes(navItem.divisionId);
          }
        } else {
          navItem.isAuthorized = true;
        }

        return;
      }

      children.forEach((childNavItem) => updateNavStatus(childNavItem));

      navItem.isAuthorized = children.findIndex((x) => !!x.isAuthorized) >= 0;
    },
    [assignedDivisions, isAuthorized]
  );

  useEffect(() => {
    sections.forEach((navSection) => {
      if (!Array.isArray(navSection.items)) {
        return;
      }

      navSection.items.forEach((item) => updateNavStatus(item));
      navSection.isAuthorized = navSection.items.findIndex((x) => !!x.isAuthorized) >= 0;
    });

    setAuthorizedSections(sections);
  }, [sections, updateNavStatus]);

  return authorizedSections;
};

export default useNavSections;
