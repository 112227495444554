import { getAccessToken } from '../../utils/jwt';
import apiConfig from '../apiConfig';
import { getAxiosInstance } from '../../utils/axiosApi';
import { httpGet } from '../../utils/apiUtils';

const axiosInstance = getAxiosInstance({
  baseUrl: apiConfig.PTPAPIUrl,
});

const axiosUtilsInstance = getAxiosInstance({
  baseUrl: apiConfig.UtilsAPIUrl,
});

axiosInstance.interceptors.request.use((config) => {
  config.headers = {
    Authorization: `Bearer ${getAccessToken()}`,
    // 'Accept': 'application/json',
  };
  return config;
});

axiosUtilsInstance.interceptors.request.use((config) => {
  config.headers = {
    Authorization: `Bearer ${getAccessToken()}`,
    // 'Accept': 'application/json',
  };
  return config;
});

const getCall = async (baseUrl, queryValueObj) => httpGet(axiosInstance, baseUrl, queryValueObj);

const getObjectConfigurationsByMultiTypeAndBelongsToRecordids = async (idPairs) => {
  const queryParams = [];

  if (!Array.isArray(idPairs) || idPairs.length === 0) {
    return null;
  }

  idPairs.forEach((item, index) => {
    const { Type, Belong, ID } = item;
    queryParams.push(`[${index}].Type=${encodeURIComponent(Type)}`);
    queryParams.push(`[${index}].Belong=${encodeURIComponent(Belong)}`);
    queryParams.push(`[${index}].ID=${encodeURIComponent(ID)}`);
  });

  try {
    const response = await axiosInstance.get(
      `/masterdata/objectconfigs/all?${queryParams.join('&')}`
    );

    return response.data;
  } catch (e) {
    throw new Error('Error search Object Configurations');
  }
};

const getUserFavoriteSettings = async ({ type, username }) => {
  try {
    const response = await axiosInstance.get(
      `/UserFavoriteSetting/all/type/${encodeURIComponent(
        type
      )}/user/${encodeURIComponent(username)}`
    );

    return response.data;
  } catch (e) {
    throw new Error('Error getUserFavoriteSettings');
  }
};

const searchListEntry = async ({
  ListName,
  ListStoreValue,
  ListStoreValueOP,
  ListDisplayValue,
  ListDisplayValueOP,
  page,
  pageSize,
  sortBy,
}) => {
  const queryParams = [];
  if (ListName && ListName.trim() !== '') {
    queryParams.push(`ListName=${encodeURIComponent(ListName.trim())}`);
  }

  if (ListStoreValue && ListStoreValue.trim() !== '') {
    queryParams.push(`ListStoreValue=${encodeURIComponent(ListStoreValue.trim())}`);
  }

  if (ListStoreValueOP && ListStoreValueOP.trim() !== '') {
    queryParams.push(`ListStoreValueOP=${encodeURIComponent(ListStoreValueOP.trim())}`);
  }

  if (ListDisplayValue && ListDisplayValue.trim() !== '') {
    queryParams.push(`ListDisplayValue=${encodeURIComponent(ListDisplayValue.trim())}`);
  }

  if (ListDisplayValueOP && ListDisplayValueOP.trim() !== '') {
    queryParams.push(`ListDisplayValueOP=${encodeURIComponent(ListDisplayValueOP.trim())}`);
  }

  if (page) {
    queryParams.push(`currentPage=${page}`);
  }

  if (pageSize) {
    queryParams.push(`pageSize=${pageSize}`);
  }

  if (sortBy) {
    queryParams.push(`sortBy=${sortBy}`);
  }

  try {
    const response = await axiosInstance.get(`/ListEntry/search/by?${queryParams.join('&')}`);

    return response.data;
  } catch (e) {
    throw new Error('Error searchListEntry');
  }
};

const searchNote = async ({
  belongsTo,
  recordID,
  title,
  page,
  pageSize,
  sortBy,
}) => {
  const queryParams = [];

  if (belongsTo) {
    queryParams.push(`belongsTo=${belongsTo}`);
  }

  if (recordID) {
    queryParams.push(`recordID=${recordID}`);
  }

  if (title) {
    queryParams.push(`title=${title}`);
  }

  if (page) {
    queryParams.push(`currentPage=${page}`);
  }

  if (pageSize) {
    queryParams.push(`pageSize=${pageSize}`);
  }

  if (sortBy) {
    queryParams.push(`sortBy=${sortBy}`);
  }

  try {
    const response = await axiosInstance.get(
      `/Note/GetNoteByObjects?${queryParams.join('&')}`
    );

    return response.data;
  } catch (e) {
    throw new Error('Error search notes');
  }
};

const addNote = async ({
  belongsTo,
  recordID,
  content,
  title,
  noteTypeName
}) => {
  try {
    const response = await axiosInstance.post(
      '/Note/Insert',
      {
        BelongsTo: belongsTo,
        RecordID: recordID,
        Content: content,
        Title: title,
        NoteTypeName: noteTypeName
      }
    );

    return response.data;
  } catch (error) {
    throw new Error('Error createCycle');
  }
};
const getNotesByBelongsToAndRecordIDs = async (belongsToRecordIDArr, abortController) => {
  try {
    const response = await axiosInstance.post('Note/GetManyNoteByManyObjects',
      belongsToRecordIDArr,
      {
        signal: abortController?.signal
      });
    return response.data;
  } catch (e) {
    throw new Error('Error getNotesByBelongsToAndRecordIDs');
  }
};

const getNotesByObjects = async ({
  belongsTo,
  recordID,
  noteType,
  pageSize,
  currentPage,
  sortBy
}) => {
  try {
    return getCall('/Note/Search/By', { belongTo: belongsTo, recordID, noteType, pageSize, currentPage, sortBy });
  } catch (error) {
    throw new Error('Error Get notes by objects');
  }
};

const getReleaseNote = async ({ version }) => {
  try {
    const response = await axiosInstance.get(
      `Note/ReleaseNote/${version}`
    );
    return response.data;
  } catch (error) {
    throw new Error('getReleaseNote error');
  }
};

const commonApi = {
  addNote,
  searchNote,
  getNotesByObjects,
  getObjectConfigurationsByMultiTypeAndBelongsToRecordids,
  searchListEntry,
  getUserFavoriteSettings,
  getReleaseNote,
  getNotesByBelongsToAndRecordIDs
};

export default commonApi;
