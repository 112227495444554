import axios from 'axios';
import apiConfig from '../apiConfig';
import { getAxiosInstance } from '../../utils/axiosApi';
import { postHandler } from '../../utils/apiUtils';

const axiosInstance = getAxiosInstance({
  baseUrl: `${apiConfig.IAMUrl}`,
});

const searchUser = async ({
  userName,
  firstName,
  lastName,
  // active,
  email,
  roleID,
  countryCode,
  requestCategoryId,
  regionId,
  locationId,
  page,
  pageSize,
  sortBy,
}) => {
  const queryParams = [];

  if (userName && userName.trim() !== '') {
    queryParams.push(`userName=${encodeURIComponent(userName.trim())}`);
    queryParams.push('userNameOP=Contains');
  }

  if (firstName && firstName.trim() !== '') {
    queryParams.push(`firstName=${encodeURIComponent(firstName.trim())}`);
    queryParams.push('firstNameOP=Contains');
  }

  if (lastName && lastName.trim() !== '') {
    queryParams.push(`lastName=${encodeURIComponent(lastName.trim())}`);
    queryParams.push('lastNameOP=Contains');
  }

  if (email && email.trim() !== '') {
    queryParams.push(`email=${encodeURIComponent(email.trim())}`);
    queryParams.push('emailOP=Contains');
  }

  if (roleID) {
    queryParams.push(`roleId=${roleID}`);
  }
  if (countryCode) {
    queryParams.push(`countryCode=${countryCode}`);
  }
  if (requestCategoryId) {
    queryParams.push(`requestCategoryId=${requestCategoryId}`);
  }
  if (regionId) {
    queryParams.push(`regionCode=${regionId}`);
  }
  if (locationId) {
    queryParams.push(`locationId=${locationId}`);
  }

  // if (active) {
  //   queryParams.push(`active=${active}`);
  // }

  if (page) {
    queryParams.push(`currentPage=${page}`);
  }

  if (pageSize) {
    queryParams.push(`pageSize=${pageSize}`);
  }

  if (sortBy) {
    queryParams.push(`sortBy=${sortBy}`);
  }
  try {
    const response = await axiosInstance.post(
      `/User/users/search/by?${queryParams.join('&')}`
    );
    return response.data;
  } catch (e) {
    if (!axios.isCancel(e)) {
      throw e;
    }
  }

  // try {
  //   // const response = await axiosInstance.get(`/User/users/all?${queryParams.join('&')}`);
  //   // return response.data;
  // } catch (e) {
  //   if (!axios.isCancel(e)) {
  //     throw e;
  //   }
  // }

  return {};
};

const getUserByUserId = async ({ userId }, abortController) => {
  try {
    const response = await axiosInstance.get(`/User/users/id/${userId}/object/all`, {
      signal: abortController?.signal
    });
    return response.data;
  } catch (e) {
    throw new Error('Error getUserByUserId');
  }
};

const UpdateUser = async ({ updateUser }) => {
  try {
    const response = await axiosInstance.post('/User/detail/update', updateUser);
    return response.data;
  } catch (e) {
    throw new Error('Error getUserByUserId');
  }
};

const resetClientPassword = async ({ tokenId, newPassword }) => {
  if (!tokenId || !newPassword) {
    throw new Error('Please check your password');
  }

  try {
    await axiosInstance.post('/user/customers/resetpassword', {
      RecoveryId: tokenId,
      Password: newPassword,
    });

    return true;
  } catch (e) {
    throw new Error('Please check your password');
  }
};

const getAllRolesdata = async () => {
  try {
    const response = await axiosInstance.get('/User/roles/all');
    return response.data;
  } catch (e) {
    throw new Error('Error getUserMasterdata');
  }
};

const searchUserAccount = async ({ username, usernameOp, loginType, restrictedClientIds, page, pageSize, sortBy }) => {
  const queryParams = [];
  if (username && username.trim() !== '') {
    queryParams.push(`username=${encodeURIComponent(username.trim())}`);

    if (usernameOp && usernameOp.trim() !== '') {
      queryParams.push(`usernameOP=${encodeURIComponent(usernameOp.trim())}`);
    } else {
      queryParams.push('usernameOP=Contains');
    }
  }

  if (loginType && loginType.trim() !== '') {
    queryParams.push(`loginType=${encodeURIComponent(loginType.trim())}`);
  }

  if (Array.isArray(restrictedClientIds) && restrictedClientIds.length > 0) {
    queryParams.push(`restrictedClientIds=${restrictedClientIds.join(',')}`);
  }

  if (page) {
    queryParams.push(`currentPage=${page}`);
  }

  if (pageSize) {
    queryParams.push(`pageSize=${pageSize}`);
  }

  if (sortBy) {
    queryParams.push(`sortBy=${sortBy}`);
  }

  try {
    const response = await axiosInstance.get(`/User/useraccounts/all?${queryParams.join('&')}`);

    return response.data;
  } catch (e) {
    // console.log(axios.isCancel);
    // console.log("axios request cancelled");
    // if (axios.isCancel(err)) {
    //   console.log("axios request cancelled");
    //   return {};
    // }

    if (!axios.isCancel(e)) {
      throw e;
    }
  }

  return {};
};

const getUserDataWithRoleByUserID = async ({ userId }) => {
  try {
    const response = await axiosInstance.get(`/User/users/id/${userId}/object/all/role/all`);

    // console.log("Search job result");
    // console.log(response);
    // {
    //   User,
    //   UserObjects,
    //   RoleClaims
    // }
    return response.data;
  } catch (e) {
    if (!axios.isCancel(e)) {
      throw e;
    }
  }

  return {};
};

/// ---- New APis

export const createUserPost = async (userData) => {
  try {
    const response = await axiosInstance.post(
      '/User/Signup',
      userData
    );

    return response.data;
  } catch (e) {
    throw new Error('Error createRequestPost');
  }
};

const updateUserDetail = async (userDetail) => {
  try {
    const response = await axiosInstance.post(
      '/User/detail/Application/update',
      userDetail
    );
    return response.data;
  } catch (e) {
    throw new Error('Error updateUserDetail');
  }
};

const UpdateUserProfile = async ({ user, updateObjects }) => {
  try {
    const response = await axiosInstance.post('/User/profile/update', {
      User: user,
      UpdateObjects: updateObjects,
    });
    return response.data;
  } catch (e) {
    throw new Error('Error UpdateUserProfile');
  }
};
const saveUserProfile = async (payload) => {
  try {
    const response = await postHandler(axiosInstance, 'User/info/save', payload);
    return response.data;
  } catch (e) {
    throw new Error('Error UpdateUserProfile');
  }
};

export const verifyUserEmailExistence = ({ email }, abortController) => postHandler(axiosInstance, '/User/VerifyExistence', {
  email
}, abortController);

const UserApi = {
  searchUser,
  getAllRolesdata,
  getUserDataWithRoleByUserID,
  searchUserAccount,
  getUserByUserId,
  UpdateUser,
  resetClientPassword,

  createUserPost,
  updateUserDetail,
  UpdateUserProfile,
  saveUserProfile,

};

export default UserApi;
