import { combineReducers } from '@reduxjs/toolkit';
import globalDataReducer from '../slices/globalData-slice';
import authRedcer from '../slices/authSlice';
import programSearchReducer from '../../pages/Program/store/slices/programSearchSlice';
import listSearchReducer from '../../pages/List/store/slices/listSearchSlice';
import locationSearchReducer from '../../pages/Location/store/slices/locationSearchSlice';
import roleSearchReducer from '../../pages/Role/store/slices/roleSearchSlice';
import userSearchReducer from '../../pages/User/store/slices/userSearchSlice';
import contentsSearchReducer from '../../pages/ContentsSearch/store/slices/contentsSearchSlice';
import myActionSearchReducer from '../../pages/MyActions/store/slices/myActionsSlice';
import actionSearchReducer from '../../pages/ActionSearch/store/slices/actionsSearchSlice';

const rootReducer = combineReducers({
  globalData: globalDataReducer,
  auth: authRedcer,
  contentsSearch: contentsSearchReducer,
  programSearch: programSearchReducer,
  listSearch: listSearchReducer,
  locationSearch: locationSearchReducer,
  roleSearch: roleSearchReducer,
  userSearch: userSearchReducer,
  myActions: myActionSearchReducer,
  actionsSearch: actionSearchReducer,
});
export default rootReducer;
