import { Link as RouterLink } from 'react-router-dom';
import { Box, Link, AppBar, useTheme } from '@mui/material';
import { blurEffectProps } from '../Cards/Effects/blurEffect';

const whiteTextColor = '#f4f5f7';

const FooterLink = ({
  href,
  text,
  target = '_blank',
  color = whiteTextColor,
  isExternal = true,
  ...other
}) => {
  if (isExternal) {
    return (
      <Link
        href={href}
        target={target}
        underline="none"
        color={color}
        variant="body2"
        rel="noopener"
        fontWeight="medium"
        fontSize={12}
        sx={{ ml: 1.5 }}
        {...other}
      >
        {text}
      </Link>
    );
  }

  return (
    <Link
      to={href}
      target={target}
      underline="none"
      color={color}
      component={RouterLink}
      variant="body2"
      fontWeight="medium"
      fontSize={12}
      sx={{ ml: 1.5 }}
      {...other}
    >
      {text}
    </Link>
  );
};

const LegalBar = (props) => {
  const theme = useTheme();
  const {
    displayLogo,
    displayCopyRight,
    linkColor,
    hideBottomBorder = false,
    isLogin = false,
  } = props;

  const logoContent = displayLogo && (
    <Box
      sx={{
        height: '32px',
        width: '32px',
        opacity: 1,
        background: 'url("/static/images/bvlogo_grey.svg") no-repeat center',
      }}
    />
  );

  const copyRightContent = displayCopyRight && (
    <FooterLink
      href="http://www.bureauveritas.com/commodities"
      text={`Copyright © Bureau Veritas ${new Date().getFullYear()}`}
    />
  );

  const barContent = (
    <>
      <Box
        px={1}
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          height: '30px',
          borderTop: `1px solid ${theme.palette.divider}`,
        }}
      >
        {logoContent}

        {copyRightContent}
        <Box sx={{ flexGrow: 1 }} />

        <FooterLink
          href=""
          text="Legal Notice"
          isExternal={false}
          color={linkColor}
        />

        <FooterLink
          href="https://personaldataprotection.bureauveritas.com"
          text="GDPR Rights Portal"
          color={linkColor}
        />

        <FooterLink
          href="#"
          text="Cookies Policy"
          isExternal={false}
          color={linkColor}
        />

        <FooterLink
          href="#"
          text="Terms of Use"
          isExternal={false}
          color={linkColor}
        />

        <FooterLink
          href="#"
          text="Information Notice To Users"
          isExternal={false}
          color={linkColor}
        />

        <FooterLink
          href="https://personaldataprotection.bureauveritas.com/privacypolicy"
          text="GDPR Data Protection Policy"
          color={linkColor}
        />
      </Box>
      {!hideBottomBorder && (
        <Box sx={{ backgroundColor: '#822433', height: '5px' }} />
      )}
    </>
  );

  if (isLogin) {
    return (
      <AppBar
        position="fixed"
        sx={{
          top: 'auto',
          bottom: 0,
          backgroundColor: 'rgba(21, 21, 21, .6)',
          backdropFilter: 'blur(10px)',
        }}
      >
        {barContent}
      </AppBar>
    );
  }

  const blurEffectSxProps = { ...blurEffectProps };
  if (theme.palette.mode === 'light') {
    blurEffectSxProps.backgroundColor = 'rgba(255,255,255,0.4)';
  }
  return <Box sx={{ ...blurEffectSxProps }}>{barContent}</Box>;
};

export default LegalBar;
