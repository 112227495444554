const enTranslation = {
  Breadcrumb_Cycles: 'Cycles',
  Breadcrumb_Contents: 'Contents',
  Breadcrumb_Home: 'Home',
  Breadcrumb_Role: 'Role',
  Breadcrumb_Actions: 'Actions',
  Breadcrumb_List: 'List',
  Breadcrumb_Program: 'Programs',
  Breadcrumb_Contacts: 'Contacts',
  Breadcrumb_Locations: 'Locations',
  Breadcrumb_Roles: 'Roles',
  Breadcrumb_Users: 'Users',
  Breadcrumb_EditCycle: 'Edit Cycle',
  Breadcrumb_Calender: 'Calendar',

  Btn_Save: 'Save',
  Btn_Start: 'Start',
  Btn_ReOpen: 'Reopen',
  Btn_Comment: 'Comment',
  Btn_Create: 'Create',
  Btn_Continue: 'Continue',
  Btn_AddLocation: 'Add Location',
  Btn_Export: 'Export',
  Btn_SaveFilter: 'Save Filter',
  Btn_SavedFilter: 'Saved Filter',
  Btn_AuditHistory: 'Audit History',
  Btn_ShowFilter: 'Show Filter',
  Btn_HideFilter: 'Hide Filter',
  Btn_Delete: 'Delete',
  Btn_Apply: 'Apply',
  Btn_ClearFilters: 'Clear Filters',
  Btn_ManageDisplay: 'Manage Display',
  Btn_Logout: 'Log Out',
  Btn_Add: 'Add',
  Btn_Cancel: 'Cancel',
  Btn_Yes: 'Yes',
  Btn_No: 'No',
  Btn_Upload: 'Upload',
  Btn_Submit: 'Submit',
  Btn_Update: 'Update',
  Btn_AddNew: 'Add New',
  Btn_StateGraph: 'State Graph',
  Btn_Close: 'Close',
  Btn_EditNa: 'Edit NA List',
  Btn_NewAction: 'New Action',
  Btn_NewEvent: 'New Event',
  Btn_Action: 'Action',
  Btn_GenerateReport: 'Generate Report',
  Btn_ShowMoreComments: 'More',
  Btn_Load5MoreLastCompletedActions: 'Load 5 more last completed actions',
  Btn_EditEnrollment: 'Edit Enrollment List',
  Btn_Audit: 'Audit',
  Btn_Audit2: 'Audit2',
  Btn_UploadThenProcess: 'Upload Then Process',
  Btn_ProcessExisting: 'Process Existing',
  Btn_AddMethods: 'Add Method(s)',
  Btn_CopyMethods: 'Copy Method(s)',
  Btn_ManageFilters: 'Manage Filters',
  Btn_Recheck: 'Recheck',
  Btn_Caveats: 'Caveats',
  Btn_Copy: 'Copy',
  Btn_Profile: 'Profile',
  Btn_Validate: 'Validate',
  Btn_Enrollment: 'Enrollment',
  Btn_ChangeMyPassword: 'Change My Password',
  Btn_NAList: 'NA List',
  Btn_Contact: 'Contact',
  Btn_PrintPDF: 'Print PDF',
  Btn_DownloadExcel: 'Download Excel',
  Btn_FinalizeAndEmail: 'Finalize and Email',
  Btn_Finalize: 'Finalize',
  Btn_Refinalize: 'Refinalize',
  Btn_Broadcast: 'Broadcast',
  Btn_SaveAsTemplate: 'Save as Template',
  Btn_Reset: 'Reset',
  Btn_AddActions: 'Add Action',
  Btn_Replicate: 'Replicate',
  Btn_Retry: 'Retry',
  Btn_Edit: 'Edit',
  Btn_Complete: 'Complete',

  Column_Action: 'Action',
  Column_Filename: 'Filename',
  Column_CreatedBy: 'Created By',
  Column_CreatedOn: 'Created On',
  Column_LastUpdatedOn: 'Updated On',
  Column_LastUpdatedBy: 'Updated By',
  Column_LastUpdatedByName: 'Updated By',
  Column_AccreditationBody: 'Accreditation Body',
  Column_ExpirationDate: 'Expiration Date',
  Column_AuditDate: 'Audit Date',
  Column_NoofMajorNC: 'No of Major NC',
  Column_NoofMinorNC: 'No of Minor NC',
  Column_NoOfRecommendations: 'No of Recommendations',
  Column_FileName: 'File Name',
  Column_UploadedOn: 'Uploaded On',
  Column_UploadedBy: 'Uploaded By',

  Error_MaxLengthIs100: 'Max Length is 100',
  Error_MaxLengthIs10: 'Max Length is 10',
  Error_Message_SomethingWentWrong: 'Something went wrong!',
  Error_LocationNameIsRequired: 'Location Name is requred',
  Error_LocationTypeIsRequired: 'Location Type is required',
  Error_CountryIsRequired: 'Country is required',
  Error_RegionIsRequired: 'Region is required',
  Error_RoleNameIsRequired: 'Role name is required',
  Error_DescriptionIsRequired: 'Description is required',
  Error_LevelIsRequired: 'Level is required',
  Error_RegionCodeIsRequired: 'Region Code is Required',
  Error_MaxLengthIs5: 'Maximum Length is 5',
  Error_ProgramNameRequired: 'Program Name is Required',
  Error_ProgramProductsRequired: 'Program Products Required',
  Error_OrganizationRequired: 'Organization is Required',
  Error_BusinessLineRequired: 'Business Line is Required',
  Error_LoadingDataError: 'Loading Data Error',
  Error_NoItemsFound: 'No items found!',
  Error_DueDateRequired: 'Due Date is required',
  Error_DateTimeFormatRequired: 'Date Time format is required',
  Error_DateFormatRequired: 'Date format is required',
  Error_ProcessDateRequired: 'Process Date is required',
  Error_CycleNameIsRequired: 'Cycle Name Is Required',
  Error_ProgramIsRequired: 'Program is Required',
  Error_MethodNameIsRequired: 'Method Name Is Required',
  Error_LocationAbbreviationIsRequired: 'Location Abbreviation is required',
  Error_MaxLengthIs3: 'Max Length is 3',
  Error_MaxLength: 'Max Length is {{length}}',
  Error_OnlyUpperCaseLettersAllowed: 'Only UpperCase Letters Allowed',
  Error_NameIsRequired: 'Name is required',
  Error_NoPresetFound: 'No preset found!',
  Error_Message_ChangePasswordError: 'Change Password Failed',
  Error_FirstNameIsRequired: 'First Name is Required',
  Error_LastNameIsRequired: 'Last Name is Required',
  Error_EmailIsRequired: 'Email is Required',
  Error_DateFormatIsRequired: 'Date Format is required',
  Error_DateTimeFormatIsRequired: 'Date Time Format is required',
  Error_MaxLengthIs50: 'Maximum Length is 50',
  Error_AccreditationBodyRequired: 'Accreditation Body is required',
  Error_ExpirationDateRequired: 'Expiration date is required',
  Error_UploadDocumentFirst: 'Upload the document first',
  Error_ReasonIsRequired: 'Reason is required',
  Error_ActionTypeRequired: 'Action Type is required',
  Error_TitleRequired: 'Title is required',
  Error_StatusNameRequired: 'Status Name is required',
  Error_AssignedToRequired: 'Assigned to is required',
  Error_TitleIsRequired: 'Title is required',
  Error_ContentGroupIsRequired: 'Content Group is required',
  Error_ContentTypeIsRequired: 'Content Type is required',
  Error_TagIsRequired: 'Tag is required',
  Error_TestItemAlreadyExists: 'Test item(s) [{{items}}] already exists in the program',

  Header_General: 'General',
  Header_Actions: 'Actions',
  Header_MyActions: 'My Actions',
  Header_Action_Column_New: 'To do',
  Header_Action_Column_Started: 'In Progress',
  Header_Action_Column_Completed: 'Completed',
  Header_ProgramInfo: 'Program Info',
  Header_AddLocation: 'Add Location',
  Header_EditLocation: 'Edit Location',
  Header_AddRole: 'Add Role',
  Header_EditRole: 'Edit Role',
  Header_List: 'List',
  Header_ProgramSearch: 'Programs',
  Header_AddProgram: 'Add Program',
  Header_EditProgram: 'Edit Program',
  Header_Action: 'Actions',
  Header_Region: 'Region',
  Header_AuditForCycle: 'Audit for - {{cycleName}}',
  Header_AddEditAccreditation: '{{op}} Accreditations - {{location}}',
  Header_Role: 'Roles',
  Header_User: 'Users',
  Header_UserCreate: 'User Create',
  Header_RoleAndAccess: 'Role And Access',
  Header_AddContent: 'Add Content',
  Header_EditContent: 'Edit Content',
  Header_ContentsSearch: 'Contents',
  Header_Locations: 'Locations',
  Header_Profile: 'Profile',
  Header_EditUser: 'Edit User',
  Header_Accreditations: 'Accreditations',
  Header_WelcomeToDashboard: 'Welcome to Knowledge Center home page.',
  Header_Calender: 'Calendar',

  Label_SortBy: 'Sort by',
  Label_Direction: 'Direction',
  Label_Ascending: 'Ascending',
  Label_Descending: 'Descending',
  Label_Edit: 'Edit',
  Label_Accreditation: 'Accreditation',
  Label_Organization: 'Organization',
  Label_ProgramName: 'Program',
  Label_CreatedBy: 'Created By',
  Label_CreatedOn: 'Created On',
  Label_ProgramProducts: 'Products',
  Label_ProgramComments: 'Program Comments',
  Label_Name: 'Name',
  Label_RoleName: 'Role Name',
  Label_CategoryGroup: 'Category Group',
  Label_Category: 'Category',
  Label_ContentName: 'Content Name',
  Label_RoleInfo: 'Role Info',
  Label_UserInfo: 'User Info',
  Label_Active: 'Active',
  Label_Title: 'Title',
  Label_Zone: 'Zone',
  Label_ActionStatus: 'Action Status',
  Label_ActionType: 'Action Type',
  Label_StatusName: 'Status Name',
  Label_AssignedTo: 'Assigned To',
  Label_CreatedOnFrom: 'Created From',
  Label_CreatedOnTo: 'Created To',
  Label_DueDateFrom: 'Due Date From',
  Label_DueDateTo: 'Due Date To',
  Label_Roles: 'Roles',
  Label_Description: 'Description',
  Label_User: 'User',
  Label_LocationName: 'Location Name',
  Label_LocationType: 'Location Type',
  Label_ContentGroup: 'Content Group',
  Label_UserType: 'User Type',
  Label_LocationAlias: 'Location Alias',
  Label_Alias: 'Alias',
  Label_Region: 'Region',
  Label_Regions: 'Regions',
  Label_Tenant: 'Tenant',
  Label_State: 'State',
  Label_City: 'City',
  Label_PostCode: 'Post Code',
  Label_AddressLine2: 'Address Line 2',
  Label_AddressLine1: 'Address Line 1',
  Label_LocationAbbreviation: 'Location Abbreviation',
  Label_Abbreviation: 'Abbreviation',
  Label_Timezone: 'Timezone',
  Label_FirstName: 'First Name',
  Label_LastName: 'Last Name',
  Label_ListName: 'List Name',
  Label_Filters: 'Filters',
  Label_Method: 'Method',
  Label_TestItem: 'Parameter',
  Label_AutoImport: 'Auto Import',
  Label_FreqYr: 'Freq/Yr',
  Label_SearchMethod: 'Search Method',
  Label_No_Records_Found: 'No records found!',
  Label_Attachments: 'Attachments',
  Label_Parameter: 'Parameter',
  Label_TestMethod: 'Method',
  Label_Unit: 'Unit',
  Label_Products: 'Products',
  Label_TestItems: 'Parameters',
  Label_LocationInfo: 'Location Information',
  Label_ContentInfo: 'Content Information',
  Label_ContentHistory: 'Content History',
  Label_VersionHistory: 'Version History',
  Label_General: 'General',
  Label_Address: 'Address',
  Label_BusinessLines: 'Business Lines',
  Label_Product: 'Product',
  Label_Email: 'Email',
  Label_DateFormat: 'Date Format',
  Label_DateTimeFormat: 'Date Time Format',
  Label_Level: 'Level',
  Label_Default: 'Default',
  Label_Type: 'Type',
  Label_Role: 'Role',
  Label_NewPassword: 'New Password',
  Label_ConfirmNewPassword: 'Confirm New Password',
  Label_Phone: 'Phone',
  Label_Countries: 'Countries',
  Label_Locations: 'Locations',
  Label_AddTag: 'Add Tag',
  Label_NND: 'NND',
  Label_Resolution: 'Resolution',
  Label_TestItemsForMethod: 'Parameters for {{ methodName }}',
  Label_Scope: 'Scope',
  Label_ListEntry: 'List Entry',
  Label_NA: 'NA',
  Label_Location: 'Location',
  Label_Country: 'Country',
  Label_BusinessLine: 'Business Line',
  Label_Program: 'Program',
  Label_Comments: 'Comments',
  Label_Tags: 'Tags',
  Label_AppliedMethodsAndTest: 'Applied Method & Parameters',

  Label_Attachment: 'Attachment',
  Label_Selected: 'Selected',
  Label_Methods: 'Methods',
  Label_EditList: 'Edit List',
  Label_ListEntries: 'List Entries',
  Label_ViewMore: 'View More',
  Label_ViewLess: 'View Less',
  Label_UpdateNAList: 'Update NA List',
  Label_AccreditationLogoText: 'Accreditation Logo Text',
  Label_CertificateHyperlink: 'Certificate Hyperlink',
  Label_ScopeHyperlink: 'Scope Hyperlink',
  Label_Latitude: 'Latitude',
  Label_Longitude: 'Longitude',
  Label_MainContact: 'Main Contact',
  Label_SavingProgramMethods: 'Saving Program Methods',
  Label_Reason: 'Reason',
  Label_HiUser: 'Hi {{username}}!',
  Label_AssignedXActions: 'There are <0>{{n}}</0> new actions assigned to you.',
  Label_XAnnouncemnets: 'There are <0>{{n}}</0> new announcemnets broadcasted to you.',
  Label_Search: 'Search',
  Label_RetriedBy: 'Retried on {{retryOn}} by {{retryBy}}',
  Label_Stretch: 'Stretch',
  Label_Compact: 'Compact',
  Label_MethodBase: 'Method Base',
  Label_ParameterBase: 'Parameter Base',
  Label_CalculationStrategy: 'Calculation Strategy',
  Label_ContentType: 'Content Type',
  Label_VisibleFor: 'Visible For',
  Label_ActionTitle: 'Action Title',
  Label_DueDate: 'Due Date',
  Message_NoComments: 'No Comments',
  Message_NoItems: 'No items!',
  Confirm_Message_UnsavedProgram: 'Do you wish to Close the window without saving the Progam?',

  Nav_Menu_Program: 'Program',
  Nav_Menu_Contents: 'Contents',
  Nav_Menu_Capability: 'Capability',
  Nav_Menu_MyActions: 'My Actions',
  Nav_Menu_Actions: 'Actions',
  Nav_Menu_Calender: 'Calendar',

  Placeholder_Selectanoption: 'Select an option',
  PlaceHolder_Comments: 'Comments',

  Tab_Title_AppName: 'CTD KC',
  Tab_Profile: 'Profile',
  Tab_Announcement: 'Announcement',
  Tab_Notification: 'Notification',

  Table_NoUserfound: 'No User Found!',

  Text_AddContents: 'Add Content',
  Text_EditContents: 'Edit Content',
  Text_AddLocation: 'Add Location',
  Text_EditLocation: 'Edit Location',
  Text_EditRole: 'Edit Role',
  Text_AddRole: 'Add Role',
  Text_True: 'True',
  Text_False: 'False',

  Title_Showing_X_Records: 'Showing {{length}} record(s)',
  Title_NewList: 'New List',
  Title_DataExport: 'Data Export',

  Toast_CreatedSuccessfully: 'Created Successfully',
  Toast_UpdatedSuccessfully: 'Updated Successfully',
  Toast_ActionStatusUpdatedSuccessfully: 'Action Status Updated Successfully',
  Toast_UpdateFailed: 'Update Failed',
  Toast_FilterDisplayUpdatedSuccessfully: 'Filter Display updated successfully!',
  Toast_FilterDisplayUpdateFailed: 'Filter Display update failed!',
  Toast_UnableToSaveFilter: 'Unable to save filter preset!',
  Toast_SavedSuccessfully: 'Save sucessfully!',
  Toast_FilesUploadedSuccessfully: 'File(s) uploaded successfully',
  Toast_FileDownloadedSuccessfully: 'File downloaded successfully!',
  Toast_FileDeletedSuccessfully: 'File(s) deleted successfully',
  Toast_MethodAndParameterAlreadyExists: 'Selected Method and Parameter already exists in Program',
  Toast_AcknowledgedSuccessfully: 'Acknowledged Successfully',

  Tooltip_ContentsSearch: 'KC | Contents Search',
  Tooltip_List: 'KC | List',
  Tooltip_Role: 'KC | Roles',
  Tooltip_User: 'KC | Users',
  Tooltip_Download: 'Download',
  Tooltip_Default: 'Default',
  Tooltip_ActionSearch: 'KC | Actions Search',
  Tooltip_AddLocation: 'KC | Add Location',
  Tooltip_EditLocation: 'KC | Edit Location',
  Tooltip_Export_To_CSV: 'Export to CSV',
  Tooltip_Locations: 'KC | Locations',
  Tooltip_AddContent: 'KC | Add Content',
  Tooltip_EditContent: 'KC | Edit Content',
  Tooltip_Favorite: 'Favorite',
  Tooltip_Unfavorite: 'Unfavorite',
  Tooltip_EditLayout: 'Edit Layout',
  Tooltip_Calendar: 'Calendar',
  Tooltip_ReadAndUnderstand: 'Read And Understand',
  Tooltip_MethodEquivalence: 'Method Equivalence',
  Tooltip_CompanySummaryReport: 'KC | Company Summary Report',
  Tooltip_DataExport: 'KC | Data Export',
  Tooltip_ReportGrading: 'KC | Report Grading',
  Tooltip_LabSummaryReport: 'KC | Location Report',
  Tooltip_AndersonDarling: 'Anderson Darling',
  Tooltip_EditUser: 'KC | User Edit',
};

export default enTranslation;
