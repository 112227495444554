import { Link as RouterLink, useLocation } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';
// import PropTypes from "prop-types";
import { Box, Drawer, IconButton, Stack, TextField, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import NavSection from './NavSection';
import Scrollbar from '../../ui/Scrollbar';

// import UserInfoSection from "./UserInfoSection";
// import useAuth from '../../../hooks/useAuth';

import { openDrawerWidth, closeDrawerWidth, navBarHeight } from './NavBar';

import useNavSections from './SidebarConfig';
import Logo from '../../ui/Logo';
import IF from '../../ui/IF';
import { useEffect, useState } from 'react';
import { blurEffectProps } from '../../Cards/Effects/blurEffect';

// const miniModeWith = 0; // 9;

const openedMixin = (theme, collapsed) => ({
  width: collapsed ? closeDrawerWidth : openDrawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  overflowY: 'hidden',
});

// const closedMixin = (theme) => ({
//   transition: theme.transitions.create('width', {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.leavingScreen,
//   }),
//   overflowX: 'hidden',
//   width: `calc(${theme.spacing(miniModeWith)} + 1px)`,
//   [theme.breakpoints.up('sm')]: {
//     width: `calc(${theme.spacing(miniModeWith)} + 1px)`,
//   },
// });

const DrawerHeader = styled('div')(({ theme }) => {
  const styleProps = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    backgroundColor: theme.palette.background.paper,
    // - Necessary for content to be below app bar
    ...theme.mixins.toolbar,
  };

  if (theme.palette.mode === 'light') {
    styleProps.boxShadow = 'rgba(37, 74, 135, 0.13) 0px 0px 28px 0px';
  }

  return styleProps;
});

const SideBarDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open, collapsed }) => {
  const blurEffectSxProps = { ...blurEffectProps };
  if (theme.palette.mode === 'light') {
    blurEffectSxProps.backgroundColor = 'rgba(255,255,255,0.5)';
  }

  return {
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': { ...openedMixin(theme, collapsed), ...blurEffectSxProps },
    }),
    // ...(!open && {
    //   ...closedMixin(theme),
    //   '& .MuiDrawer-paper': { ...closedMixin(theme, collapsed), backgroundColor: '#212225' },
    // }),
  };
});

export const PTPLogoLink = ({ collapsed }) => (
  <Box sx={{ width: '100%' }}>
    <RouterLink to="/">

      <Box
        sx={{
          height: navBarHeight,
          textAlign: 'center',
          '& > img': {
            maxHeight: '100%',
            width: 'auto',
          },
        }}
        p={1}
      >
        <Logo collapsed={collapsed} />
      </Box>
    </RouterLink>
  </Box>
);

const Sidebar = (props) => {
  const { onSidebarClose, isSidebarOpen } = props;
  const { pathname } = useLocation();
  const sections = useNavSections();
  const muiTheme = useTheme();

  const [isOpen, setIsOpen] = useState(false);
  const [options, setOptions] = useState([]);

  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  useEffect(() => {
    setOptions(sections);
  }, [sections, isSidebarOpen]);

  const onNavigateHandler = (path) => {
    if (!lgUp && isSidebarOpen && pathname !== path) {
      onSidebarClose();
    }
  };

  const onClickHandler = () => {
    setIsOpen(!isOpen);
    setOptions(sections);
  };

  const handleInputChange = (searchedVal) => {
    const filteredData = sections?.map((row) => ({
      ...row,
      items: row.items.filter((x) => x.title.trim().toLowerCase().includes(searchedVal.toLowerCase()))
    }));
    setOptions(filteredData);
  };

  const content = (
    <div
      className="hoverableScroll"
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        borderRight: `1px solid ${muiTheme.palette.divider}`,
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        {/* <Box
          sx={{
            display: {
              lg: "none",
              xs: "flex",
            },
            justifyContent: "center",
            p: 2,
          }}
        >
          <RouterLink to="/">
            <Logo
              sx={{
                height: 40,
                width: 40,
              }}
            />
          </RouterLink>
        </Box> */}

        {/*
        <UserInfoSection
          avatar={user.avatar}
          name={user.fullName}
          role={user.role}
        /> */}

        {/* <Divider /> */}

        <Box px={1}>
          {options.filter((x) => !!x.isAuthorized).map((option) => (
            <NavSection
              collapsed={isSidebarOpen ? 0 : 1}
              key={option.title}
              pathname={pathname}
              sx={{
                '& + &': {
                  mt: 3,
                },
              }}
              onNavigate={onNavigateHandler}
              {...option}
            />
          ))}
        </Box>

        {/* <Divider /> */}

        {/* <Box sx={{ p: 2 }}>
          <Typography color="textPrimary" variant="subtitle2">
            Need Help?
          </Typography>
          <Typography color="textSecondary" variant="body2">
            Check our docs
          </Typography>
          <Button
            color="primary"
            component={RouterLink}
            fullWidth
            sx={{ mt: 2 }}
            to="/docs"
            variant="contained"
          >
            Documentation
          </Button>
        </Box> */}
      </Scrollbar>
    </div>
  );

  return (
    <SideBarDrawer
      open
      variant="permanent"
      collapsed={isSidebarOpen ? 0 : 1}
      sx={{ width: isSidebarOpen ? openDrawerWidth : closeDrawerWidth }}

    >
      <DrawerHeader sx={{ borderRight: `1px solid ${muiTheme.palette.divider}` }}>
        <PTPLogoLink collapsed={isSidebarOpen ? 0 : 1} />
      </DrawerHeader>

      <Stack alignItems="center" sx={{ borderRight: `1px solid ${muiTheme.palette.divider}` }}>
        <IF condition={isSidebarOpen && !isOpen}>
          <IconButton size="small" color="textPrimary" onClick={onClickHandler}>
            {!isOpen && <KeyboardArrowDownIcon />}
          </IconButton>
        </IF>

        <IF condition={isOpen && isSidebarOpen}>
          <TextField
            autoFocus
            autoComplete="off"
            onChange={(e) => {
              handleInputChange(e.target.value);
            }}
            variant="standard"
            // placeholder={`${t('Label_Search')}...`}
            size="small"
            fullWidth
            sx={{
              p: 0.5,
              pr: 0,
              // '& .MuiOutlinedInput-root': { borderRadius: 1 },
            }}
            InputProps={{
              endAdornment: (
                <IconButton size="small" color="textPrimary" onClick={onClickHandler}>
                  {isOpen && <KeyboardArrowUpIcon />}
                </IconButton>
              ),
            }}
          />
        </IF>
      </Stack>

      {content}
    </SideBarDrawer>
  );
};

// DashboardSidebar.propTypes = {
//   onMobileClose: PropTypes.func,
//   openMobile: PropTypes.bool,
// };

export default Sidebar;
